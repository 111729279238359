.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;
}


.App {
  text-align: center;
}

 /*.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

  
 /*.App-header {
 min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(40, 35, 35);
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 450px) { /* Максимальная ширина для мобильных устройств */
  /* .App {
    justify-content: center;
    align-items: center;
  } */
}
@media (max-width: 450px) { 
  /* .top-container {
    width: 100%;
    display: inline-block;
    gap: 20px; 
    justify-content: center; 
    align-items: center;
  }

  .top-container-item {
    width: 100%;
    max-width: 450px;
    margin-bottom: 15px;
  }
  
  .Flex-container {

    width: 100%;
  }
  
  .Flex-container-item {

    width: 100%;
  }
  .App-header{
    justify-content: center;
    width: 25rem;
  } */
}

.Flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Расстояние между элементами */
  justify-content: center; /* Выравнивание элементов по центру горизонтально */
  align-items: center; /* Выравнивание элементов по центру вертикально (если нужно) */
  flex-direction: column;
}

.Flex-container-item {
  flex: 1 1 200px; /* Минимальная ширина блока */
  max-width: 500px; /* Максимальная ширина блока */
  margin-bottom: 15px; /* Отступ снизу */
}

.main-container {
  width: 100%; /* Ширина контейнера равна 100% ширины экрана */
  overflow: hidden; /* Скрывает любое содержимое, выходящее за рамки */
  box-sizing: border-box; /* Включает padding и border в ширину элемента */
  padding: 5px; /* Отступы внутри контейнера */
}

.main-container-block{
  width: 100%; /* Делаем блоки на всю ширину контейнера */
  margin: 5px 0; /* Отступы для блоков */
  text-align: center; /* Центрирование текста в блоках */
  background-color: #b5e1e1; /* Фон блоков, для наглядности */
  padding: 0px; /* Паддинг внутри блоков */
}








