
@import url(https://fonts.googleapis.com/css?family=Tenor+Sans|PT+Serif:400,400italic,700,700italic&subset=latin,cyrillic);

.info-block {
    background-color: #f0f0f0; /* Светлый фон для контраста */
    /* max-width: 400px; Максимальная ширина блока */
    border-radius: 10px; /* Скругленные углы */
  }
  
  .lafeta-line {
    
    font-weight: initial;
    color: rgb(182, 49, 49); /* Цвет текста */
    margin: 00px 0; /* Отступы между строками */
  }
  
  .lafeta-first {
    margin-top: 1.5rem;
    font-size: 24px; /* Размер шрифта */
    font-weight: bold; /* Жирный шрифт */
    color: #cee0f3; /* Синий цвет */
  }
  
  .lafeta-second {
    margin-top: 1.5rem;
    font-size: 24px; /* Немного меньше размер шрифта */
    font-weight: bold; 
    color: #e0f5e4; /* Зеленый цвет */
  }
  
  .lafeta-third {
    margin-top: 1.5rem;
    font-size: 24px; /* Размер шрифта как у второй строки */
    font-weight: bold; 
    /*text-transform: uppercase;
    /*text-decoration: underline;  Подчеркнутый текст */
    color: #f4e1e3; /* Красный цвет */
  }
  
    /* Медиа-запрос для мобильных устройств */
    @media (max-width: 500px) {
      .lafeta {
        justify-content: center;
        flex-direction: column; /* Элементы теперь расположены вертикально */
        align-items: center; /* Центрирование элементов по горизонтали */
        padding: 0px; /* Отступы для контейнера, чтобы картинки не касались краев экрана*/
        padding-top: 10px;/* Добавляем прокрутку, если картинки не помещаются*/
        overflow: hidden; /* Скрываем всё, что выходит за пределы блока */
        gap: 0px; /* Промежуток между ...*/
        width: '20rem',
      }

      .lafeta-device {
        justify-content: center;
      }
  
    }

    .lafeta{
      display: flex;
      justify-content: center;
      gap: 20px; /* Промежуток между ...*/
      padding: 5px; /* Отступы для контейнера, чтобы картинки не касались краев экрана*/
      margin-top: 25px;
      overflow: hidden; /* Скрываем всё, что выходит за пределы блока */
    }