/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Это позволяет избежать неожиданных полей вокруг блоков */
/*} */
body {
 /* margin: 0;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*margin-top: 0px;
  /*padding-top: 0%;
  /*height: 100%; /* Убедитесь, что html и body занимают всю высоту страницы */
  /*margin: 0 !important; /* Убираем поля */
  /*padding: 0; /* Убираем отступы */
  background-color: #b5e1e1 !important;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Предотвращает появление горизонтального скролла */  
 /* height: 100%; /* Полная высота */
  margin: 0; /* Убираем маргины */
  display: flex;
  justify-content: center; /* Горизонтальное выравнивание */
  align-items: center; /* Вертикальное выравнивание */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}