@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.info-section {
    background: #2c3e50; /* Темный фон для контрастности с текстом */
    background: linear-gradient(145deg, #2c3e50, #34495e); /* Градиент для добавления глубины */
    color: #ffffff; /* Белый цвет текста для лучшей читаемости */
    padding: 10px; /* Отступы вокруг контента */
    margin: 1ch;
    border-radius: 10px 10px 10px 10px; /* Скругленные углы */
    text-align: center; /* Центрирование текста */
    /*font-family: 'Roboto', sans-serif; /* Семейство шрифтов Roboto, доступно на Google Fonts */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Тень для создания "поднятого" эффекта */
    margin-top: 165px;
  }
  
  h1 {
    /*font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;*/
    margin-bottom: 0px; /* Отступ снизу заголовка */
    margin-top: 0px;
  }
  
  .phone-number {
    /*font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;*/
    font-size: 3.5rem; /* Выделение номера телефона */
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .info-period  {
    /*font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;*/
    font-size: 2.5rem; /* Выделение номера телефона */
    padding-bottom: 20px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .info-service  {
    /*font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;*/
    /* text-transform: lowercase; */
    font-size: 2.05rem; /* Выделение номера телефона */
    margin-bottom: 5px;
  }

  .top-section{
    padding-top: 130px;
  }
  
  /* Можно добавить медиа-запросы для адаптивного дизайна */
  @media (max-width: 768px) {
    /* .info-section {
      padding: 30px;
    }
    
    h1 {
      font-size: 2rem;
    }*/
  
    .phone-number {
      font-size: 2.25rem;
    } 
  }
  /* Медиа-запрос для планшетов */
  /* @media (max-width: 768px) {
    .info-section {
      padding-top: 40px; /* Меньший отступ для планшетов
    }

  } */

  /* Медиа-запрос для мобильных устройств */
  @media (max-width: 500px) {

     .info-section{
      margin-top: 90px;
      padding-top: 20px;
    } 
  }
  
  .top-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed; /* Фиксируем панель */
    top: 0; /* Располагаем панель вверху страницы */
    left: 0; /* Выравниваем панель по левому краю */
    width: 100%; /* Задаём ширину панели равной ширине окна браузера */
    z-index: 1000; /* Устанавливаем z-index, чтобы панель была над другими элементами */
    padding: 0; /* Добавляем некоторый отступ сверху и снизу для содержимого панели */
    background: linear-gradient(145deg, #2c3e50, #34495e); /* Градиент для добавления глубины */
    color: #ffffff; /* Белый цвет текста для лучшей читаемости */
    padding: 0;
    margin: 0;
    text-align: center; /* Центрирование текста */
    /*font-family: 'Roboto', sans-serif; /* Семейство шрифтов Roboto, доступно на Google Fonts */
    /*box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Тень для создания "поднятого" эффекта */
    border-radius: 0 0 40px 40px; /* Скругленные углы */
  }

  .fixed-top-center {
    position: fixed; /* Фиксированное позиционирование */
    top: 0; /* Прижимаем элемент к верху страницы */
    left: 50%; /* Позиционирование от левого края на 50% ширины вьюпорта */
    transform: translateX(-50%); /* Смещение элемента обратно на 50% его собственной ширины по горизонтали */
    width: 100%; /* Задаем фиксированную ширину */
    height: auto; /* Задаем фиксированную высоту */
    background: linear-gradient(145deg, #2c3e50, #34495e); /* Градиент для добавления глубины */
    border-radius: 0 0 40px 40px; /* Скругленные углы */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Тень для лучшей видимости */
    z-index: 1000; /* Убедимся, что элемент будет поверх других элементов */
    padding: 10px; /* Внутренние отступы */
    box-sizing: border-box; /* Граница и паддинг включены в ширину/высоту */
    color: #ffffff; /* Белый цвет текста для лучшей читаемости */
  }
  